import React, {useEffect} from 'react';

import {OutboundLink} from 'gatsby-plugin-gtag';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import huaweiBannerImageBlack from '../../images/huawei-badge-black.png';
import {styledPayStatus} from '../../styled/styledPayStatus';
import AppleStoreBadge from '!svg-react-loader!../../images/apple-store-badge.svg?name=AppleStoreBadge';
import styled from 'styled-components';

const Claim = ({className}) => {
  useEffect(() => {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href = 'market://details?id=com.foongit';
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href = 'itms-apps://itunes.apple.com/app/id1554161305';
    };
  }, []);

  return (
    <Layout className={className}>
      <SEO title="Claim funds" />
      <div className="message">
        <span>Redirecting...</span>
        <span>You will be redirected to your app store.</span>
        <span>If you are not redirected, navigeate to the store with the following links:</span>
        <div className="app-store-links">
          <OutboundLink href='https://play.google.com/store/apps/details?id=com.foongit'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></OutboundLink>
          <OutboundLink className="badge-link" href="https://itunes.apple.com/za/app/foongit/id1554161305?ls=1&mt=8">
            <AppleStoreBadge />
          </OutboundLink>
          <OutboundLink href='https://appgallery.huawei.com/app/C104316427' className="huawei"><img alt='Get it on Huawei App Gallery' src={huaweiBannerImageBlack} /></OutboundLink>
        </div>
      </div>
    </Layout>
  );
}

export default styled(styledPayStatus(Claim))`
  > .body .layout .message {
    height: auto;
    .app-store-links {
      display: grid;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > a {
        margin: 0;
      }
      img {
        height: 4rem;
      }
      svg {
        width: 8rem;
        height: 3rem;
      }
      .huawei img {
        height: 2.8rem;
        margin-left: 12px;
      }
    }
  }
`;
